<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择所属影院" v-model:value="formState.cinemaId" allow-clear
							style="width: 180px;">
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
								{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<!-- <a-form-item label="用户ID" name="userId" class="ui-form__item">
						<a-input v-model:value="formState.userId" placeholder="请输入用户ID"></a-input>
					</a-form-item> -->
				</a-row>

				<a-row v-show="showAll">
					<a-form-item label="支付方式" name="payMessageType" class="ui-form__item">
						<a-select v-model:value="formState.payMessageType" placeholder="请选择支付方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="'wx_lite'">微信支付</a-select-option>
							<a-select-option :value="'card'">一卡通支付</a-select-option>
							<a-select-option :value="'zero'">0元支付</a-select-option>
							<!-- <a-select-option :value="'alipay'">支付宝支付</a-select-option> -->
						</a-select>
					</a-form-item>

					<a-form-item label="卖品类型" name="type" class="ui-form__item">
						<a-select v-model:value="formState.type" placeholder="请选择卖品类型" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">单品</a-select-option>
							<a-select-option :value="5">套餐</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="购买时间" class="ui-form__item">
						<a-range-picker v-model:value="createTime"></a-range-picker>
					</a-form-item>

					<a-form-item label="核销时间" class="ui-form__item">
						<a-range-picker v-model:value="verifyTime"></a-range-picker>
					</a-form-item>

					<!-- <a-form-item label="退款时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item> -->
				</a-row>

				<a-row>
					<a style="margin-bottom: 10px" @click="showAll = !showAll">
						{{ showAll ? '收起' :'展开' }}
						<Icon v-show="!showAll" icon="DownOutlined"></Icon>
						<Icon v-show="showAll" icon="UpOutlined"></Icon>
					</a>
				</a-row>

				<a-row>
					<a-col :span="18">
						<span v-permission="['snack_order_list_import']">
							<exportReport type="snackOrderExportStrategy" :searchData="{status: (this.activeKey == 0 ? undefined : this.activeKey), ...searchData}"></exportReport>
						</span>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<a-tabs style="margin-top: 10px;" v-model:activeKey="activeKey" size="small" type="card"
				@change="onListChange">
				<a-tab-pane key="0" tab="全部"></a-tab-pane>
				<a-tab-pane key="WAIT_BUYER_PAY" tab="待支付"></a-tab-pane>
				<a-tab-pane key="WAIT_CHECK" tab="未核销"></a-tab-pane>
				<a-tab-pane key="TRADE_FINISH" tab="已核销"></a-tab-pane>
				<a-tab-pane key="AFTERSALE_FINISH" tab="已退款"></a-tab-pane>
				<a-tab-pane key="CANCEL" tab="已关闭"></a-tab-pane>
			</a-tabs>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
					:scroll="{ x: 3850 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'status'">
							<div v-if="record.status === 'SYSTEM_CANCEL'">系统超时取消</div>
							<div v-if="record.status === 'CUSTOMER_CANCEL'">用户自行取消</div>
							<div v-if="record.status === 'WAIT_BUYER_PAY'">待付款</div>
							<div v-if="record.status === 'WAIT_GROUP'">待成团</div>
							<div v-if="record.status === 'WAIT_SELLER_SEND_GOODS'">待发货</div>
							<div v-if="record.status === 'WAIT_CHECK'">待核销</div>
							<div v-if="record.status === 'WAIT_BUYER_CONFIRM_GOODS'">待确认收货</div>
							<div v-if="record.status === 'EXPIRED'">已过期</div>
							<div v-if="record.status === 'WAIT_COMMENT'">待评价</div>
							<div v-if="record.status === 'TRADE_FINISH'">订单完成</div>
							<div v-if="record.status === 'AFTERSALE_FINISH'">售后完成</div>
						</template>

						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'payTime'">
							{{ transDateTime(record.payTime) }}
						</template>
						<template v-if="column.key === 'orderInfo'">
							<div>
								<div>订单号：{{ record.orderNo || '-' }}</div>
								<div>商户流水号：{{ record.outTradeNo || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'discountType'">
							<div>
								{{ ['无', '优惠券', '一卡通'][record.discountType] }}
							</div>
						</template>
						<template v-if="column.key === 'deliveryType'">
							<div>{{ ['快递', '到店核销', '无'][record.deliveryType - 1] }}</div>
						</template>
						<template v-if="column.key === 'verificationCode'">
							<div v-if="record.deliveryType === 2">
								<div>核销码：{{ record.verificationCode || '-' }}</div>
								<div>核销时间：{{ transDateTime(record.signTime) }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'userInfo'">
							<div>
								<div>用户昵称：{{ record.nickname || '-' }}</div>
								<div>用户手机号：{{ record.phone || '-' }}</div>
							</div>
						</template>
						<template v-if="column.key === 'payMessageType'">
							<div v-if="record.payMessageType === 'wx_lite'">微信支付</div>
							<div v-else-if="record.payMessageType === 'card'">一卡通支付</div>
							<div v-else-if="record.payMessageType === 'alipay'">支付宝支付</div>
							<div v-else-if="record.payMessageType === 'zero'">0元支付</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'orderItem'">
							<div>
								<div v-for="item in record.orderItemList" :key="item.id" class="ui-order__item">
									{{ item.goodsTitle }}
								</div>
							</div>
						</template>
						<template v-if="column.key === 'skuName'">
							<div v-for="item in record.orderItemList" :key="item.id" class="ui-order__item">
								<div v-if="item.skuName">{{ item.skuName }}</div>
								<div v-else>{{ item.goodsTitle }}</div>
							</div>
						</template>
						<template v-if="column.key === 'verification'">
							<div>
								{{ ['WAIT_COMMENT', 'TRADE_FINISH'].indexOf(record.status) == -1 ? '未核销' : '已核销' }}
							</div>
						</template>
						<template v-if="column.key === 'fees'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment * 0.0054).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'predict'">
							<div v-if="record.payMessageType === 'wx_lite'">{{ (record.payment - (record.payment * 0.0054)).toFixed(3) }}</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'afterInfo'">
							<div v-if="record.orderAfterSale">
								<div>售后原因：{{ record.orderAfterSale.reason || '-' }}</div>
								<div>退款金额：{{ record.orderAfterSale.refundTotal }}</div>
								<div>申请时间：{{ transDateTime(record.orderAfterSale.createTime) }}</div>
								<div>退款时间：{{ transDateTime(record.orderAfterSale.refundTime) }}</div>
							</div>
							<div v-else>-</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']"
								v-if="record.status === 'WAIT_CHECK' || record.status === 'TRADE_FINISH'">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['snack_order_list_refund']" @click="onShowRefund(record)"
											v-if="record.status === 'WAIT_CHECK' || record.status === 'TRADE_FINISH'">
											<a-menu-item>
												退款
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
							<div v-else>-</div>
						</template>
					</template>
				</a-table>
			</div>

			<a-modal v-model:visible="showRejectModal" destroyOnClose title="拒绝" width="500px">
				<a-spin :spinning="loading">
					<a-form ref="rejectForm" scrollToFirstError :model="modelRef" name="rejectForm"
						:labelCol="{span: 5}" :wrapperCol="{span: 15 }">
						<a-form-item label="备注信息" name="info" :rules="[{ required: true, message: '必填项不允许为空' }]">
							<a-textarea v-model:value="modelRef.info"></a-textarea>
						</a-form-item>
					</a-form>
				</a-spin>
			</a-modal>

			<a-modal v-model:visible="showRefund" title="退款" width="810px" @ok="onRefundOk">
				<div>请选择要退款的卖品：</div>
				<div style="margin-top: 20px;">
					<a-table :columns="refundColumns" :dataSource="refundList" rowKey="goodsId"
						:rowSelection="{ type: rowSelectionType, selectedRowKeys: selectedRefundRowKeys, onSelect: onSelectRefundChange, onSelectAll: onSelectRefundAll }"
						:scroll="{ x: 500 }">
						<template #bodyCell="{ column, record }">
							<!--              <template v-if="column.key === 'refund'">
                <a-input-number v-model:value="record.refund"></a-input-number>
              </template>-->
						</template>
					</a-table>
				</div>
				<a-row style="margin-top: 20px; color: #c2c2c2">
					<a-col>
						<div>注：</div>
					</a-col>
					<a-col>
						<div>退款商品使用了兑换券，兑换券会一并退回；</div>
						<div>退款商品使用了抵扣券，则需所有商品退款才会退回抵扣券；</div>
						<div>退款商品为卡券商品且用户未进行兑换，卡券将一并回收；</div>
						<div>退款商品为卡券商品且用户已兑换，则卡券不可回收；</div>
					</a-col>
				</a-row>
			</a-modal>
		</a-spin>

	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import exportReport from '@/components/exportReport/exportReport.vue';
	import {
		getOrderBaseSnackList
	} from "@/service/modules/report";
	import {
		getCinemaAllList
	} from '@/service/modules/cinema.js';
	import {
		getOrderAfterSalePreview,
		saveOrderAfterSale
	} from "@/service/modules/mall";
	export default {
		components: {
			Icon,
			exportReport
		},
		data() {
			return {
				activeKey: '0',
				showAll: false,
				showRefund: false,
				showRejectModal: false,
				id: 0,
				loading: false,
				rowSelectionType: 'checkbox',
				selectedRowKeys: [],
				selectedRefundRowKeys: [],
				cinemaAllList: [],
				searchData: {},
				modelRef: {},
				time: [],
				createTime: [],
				verifyTime: [],
				formState: {},
				refundColumns: [{
						title: '卖品名称',
						dataIndex: 'title',
					}, {
						title: '实付金额',
						dataIndex: 'payment',
					},
					//   {
					//   title: '退款金额',
					//   key: 'refund',
					// }
				],
				refundList: [],
				columns: [{
					title: '下单时间',
					key: 'createTime',
					width: 180
				},{
					title: '支付时间',
					key: 'payTime',
					width: 180
				}, {
					title: '所属影院',
					dataIndex: 'cinemaName',
				}, {
					title: '订单信息',
					key: 'orderInfo'
				}, {
					title: '订单状态',
					key: 'status',
					width: 150
				}, {
					title: '支付方式',
					key: 'payMessageType',
					width: 150
				}, {
					title: '卖品信息',
					key: 'orderItem'
				}, {
					title: "单品信息",
					key: 'skuName'
				}, {
					title: '应付金额',
					dataIndex: 'totalFee',
					width: 150
				}, {
					title: '实付金额',
					dataIndex: 'payment',
					width: 150
				}, {
					title: '优惠方式',
					key: 'discountType',
					width: 150
				}, {
					title: '配送方式',
					key: 'deliveryType',
					width: 150
				}, {
					title: '核销信息',
					key: 'verificationCode'
				}, {
					title: '核销状态',
					key: 'verification',
					width: 150
				}, {
					title: '用户信息',
					key: 'userInfo'
				}, {
					title: '售后信息',
					key: 'afterInfo'
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					width: 150,
					align: 'center'
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						if (this.activeKey === '0') {
							this.getData();
						} else {
							this.getData(this.activeKey);
						}
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						if (this.activeKey === '0') {
							this.getData();
						} else {
							this.getData(this.activeKey);
						}
					}
				},
			}
		},
		created() {
			this.getData();
			this.getAllCinemaList();
		},
		methods: {
			reset() {
				this.time = [];
				this.createTime = [];
				this.verifyTime = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.activeKey = '0';
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.createTime && this.createTime.length === 2) {
					this.searchData.createStartTime = this.moment(this.createTime[0].startOf('days')).unix();
					this.searchData.createEndTime = this.moment(this.createTime[1].endOf('days')).unix();
				}
				if (this.verifyTime && this.verifyTime.length === 2) {
					this.searchData.verifyStartTime = this.moment(this.verifyTime[0].startOf('days')).unix();
					this.searchData.verifyEndTime = this.moment(this.verifyTime[1].endOf('days')).unix();
				}
				if (this.activeKey === '0') {
					this.getData();
				} else {
					this.getData(this.activeKey);
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				let ret = await getCinemaAllList({});
				if (ret.code === 200) {
					this.cinemaAllList = ret.data;
				}
			},
			async getData(status) {
				this.loading = true;
				try {
					let ret = await getOrderBaseSnackList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						status: status,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onListChange() {
				if (this.activeKey === '0') {
					this.getData();
				} else {
					this.getData(this.activeKey);
				}
			},
			onSelectChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						// this.selectedRowData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						// this.selectedRowData = this.selectedRowData.filter(item => {
						// 	return item.id !== record.id;
						// });
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							// this.selectedRowData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							// this.selectedRowData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onSelectRefundChange(record, selected) {
				if (this.rowSelectionType === 'checkbox') {
					if (selected) {
						this.selectedRefundRowKeys.push(record.goodsId);
					} else {
						this.selectedRefundRowKeys.splice(this.selectedRefundRowKeys.indexOf(record.goodsId), 1);
					}
				}
			},
			onSelectRefundAll(selected) {
				if (selected) {
					this.refundList.forEach(item => {
						if (this.selectedRefundRowKeys.indexOf(item.goodsId) === -1) {
							this.selectedRefundRowKeys.push(item.goodsId);
						}
					})
				} else {
					this.refundList.forEach(item => {
						if (this.selectedRefundRowKeys.indexOf(item.goodsId) !== -1) {
							this.selectedRefundRowKeys.splice(this.selectedRefundRowKeys.indexOf(item.goodsId), 1);
						}
					})
				}
			},
			onShowRefund(item) {
				this.id = item.id;
				this.getOrderAfterSalePreview();
			},
			async getOrderAfterSalePreview() {
				this.loading = true;
				try {
					let ret = await getOrderAfterSalePreview({
						orderId: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						this.refundList = ret.data.orderItemList;
						this.refundList.forEach(item => {
							item.refund = item.payment;
						});
						this.selectedRefundRowKeys = [];
						if (ret.data.orderItemList.length === 1) {
							this.selectedRefundRowKeys.push(ret.data.orderItemList[0].goodsId);
						}
						this.showRefund = true;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			onRefundOk() {
				if (this.selectedRefundRowKeys.length === 0) {
					this.$message.error('请选择要退款的订单');
					return;
				}
				let orderItemIdList = [];
				this.refundList.forEach(item => {
					if (this.selectedRefundRowKeys.includes(item.goodsId)) {
						orderItemIdList.push(item.orderItemId)
						// orderItemList.push({
						//   orderItemId: item.orderItemId,
						//   refund: item.refund
						// })
					}
				})
				// let flag = false;
				// orderItemList.forEach(item => {
				//   if(!item.refund) {
				//     flag = true;
				//     return;
				//   }
				// })
				// if(flag) {
				//   this.$message.error('请填写退款金额');
				//   return;
				// }
				this.$confirm({
					title: '提示',
					content: '确认要退款吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await saveOrderAfterSale({
								orderId: this.id,
								orderItemIdList
							})
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.showRefund = false;
								this.getData();
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
	.ui-order__item {
		padding: 4px 6px;
		margin-bottom: 4px;
		border: solid 1px #eee;
		border-radius: 6px;
	}
</style>
